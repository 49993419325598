
import { format, isSameDay, startOfDay } from 'date-fns';
import { nl } from 'date-fns/locale';
import { computed, defineComponent } from 'vue';

import { ArticleListItem } from '@/services/api/modules/article.types';

import Column from './Column.vue';

export default defineComponent({
  components: {
    Column,
  },

  props: {
    resourceId: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array as () => ArticleListItem[],
      required: false,
    },
    position: {
      type: Date,
      required: true,
    },
  },

  setup(props) {
    const day = computed(() => startOfDay(props.position));

    const column = computed(() => {
      return {
        title: format(new Date(day.value), 'EEEEEE dd MMMM', { locale: nl }),
        items: props.items?.filter((item) =>
          item.dayRange?.some((date) => isSameDay(day.value, date)),
        ),
      };
    });

    return { column };
  },
});
