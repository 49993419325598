import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
    return (_openBlock(), _createBlock(_component_Column, {
      key: column,
      resourceId: _ctx.resourceId,
      isLoading: _ctx.isLoading,
      title: column.title,
      items: column.items
    }, null, 8, ["resourceId", "isLoading", "title", "items"]))
  }), 128))
}