
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  isSameDay,
  startOfWeek,
} from 'date-fns';
import { nl } from 'date-fns/locale';
import { computed, defineComponent } from 'vue';

import { ArticleListItem } from '@/services/api/modules/article.types';

import Column from './Column.vue';

export default defineComponent({
  components: {
    Column,
  },

  props: {
    resourceId: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array as () => ArticleListItem[],
      required: false,
    },
    position: {
      type: Date,
      required: true,
    },
  },

  setup(props) {
    const days = computed(() =>
      eachDayOfInterval({
        start: startOfWeek(props.position, { weekStartsOn: 1 }),
        end: endOfWeek(props.position, { weekStartsOn: 1 }),
      }),
    );

    const columns = computed(() =>
      days.value.map((day) => ({
        title: format(new Date(day), 'EEEEEE dd MMMM', { locale: nl }),
        items: props.items?.filter((item) =>
          item.dayRange?.some((date) => isSameDay(day, date)),
        ),
      })),
    );

    return { columns };
  },
});
