
import { add, differenceInHours, startOfDay, startOfWeek, sub } from 'date-fns';
import { computed, defineComponent, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from 'vue-query';
import { useRoute } from 'vue-router';

import api from '@/services/api';

import DayView from '../components/DayView.vue';
import WeekView from '../components/WeekView.vue';

const getFetchParams = (type: string, date: Date) => {
  const fetchParams =
    type === 'day'
      ? { days: 1, date: startOfDay(date) }
      : { days: 7, date: startOfWeek(date, { weekStartsOn: 1 }) };
  return fetchParams;
};

export default defineComponent({
  components: {
    WeekView,
    DayView,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const changed = ref(false);
    const typeOptions = ref([
      { text: t('common.day'), value: 'day' },
      { text: t('common.week'), value: 'week' },
    ]);
    const type = ref(typeOptions.value[1]);
    const position = ref(new Date());

    const resourceId = computed(() => route.params.resourceId as string);

    const today = () => {
      position.value = new Date();
    };

    const previous = () => {
      const typePlural = `${type.value.value}s`;
      position.value = sub(position.value, { [typePlural]: 1 });
    };

    const next = () => {
      const typePlural = `${type.value.value}s`;
      position.value = add(position.value, { [typePlural]: 1 });
    };

    const handlePrint = () => {
      window.print();
    };

    const fetchEvents = async () => {
      if (resourceId.value) {
        const query = isNaN(Number(resourceId.value));
        const module = query ? api.queries : api.calendars;

        const { days, date } = getFetchParams(type.value.value, position.value);

        const data = await module.items(resourceId.value, {
          count: 200,
          days,
          from: date,
        });

        return data;
      }
    };

    const queryKey = reactive(['calendar', { resourceId, type, position }]);
    const { data, isLoading } = useQuery(queryKey, fetchEvents);

    const items = computed(() => {
      if (!changed.value) return data.value?.items;

      return data.value?.items.filter((item) => {
        const modified = new Date(item.modified);
        const difference = differenceInHours(new Date(), modified);

        return difference < 24;
      });
    });

    return {
      resourceId,
      position,
      changed,
      type,
      today,
      previous,
      next,
      handlePrint,
      isLoading,
      items,
      typeOptions,
    };
  },
});
