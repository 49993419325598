/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { ActionContext } from 'vuex';

import {
  RESOURCE_KINDS,
  WORKSPACE_KINDS,
  WORKSPACE_TYPES,
} from '@/config/enums';
import i18n from '@/plugins/i18n';
import api from '@/services/api';
import { StoreQuery } from '@/services/api/modules/queries';
import { UpdateName } from '@/services/api/modules/queries';
import {
  DeleteItem,
  DeleteResource,
  Resource,
  StoreItem,
  StoreResource,
  UpdateItem,
  UpdateResource,
  Workspace,
} from '@/services/api/modules/workspaces';
import mapResourceId from '@/services/mapping/mapResourceId';
import { State as R } from '@/store/index';

export interface State {
  items: Workspace[];
}

export default {
  namespaced: true,

  state: {
    items: [],
  },

  mutations: {
    setItems(state: State, payload: { items: Workspace[] }): void {
      state.items = payload.items;
    },

    updateResource(state: State, payload: Resource): void {
      const { workspaceIndex, resourceIndex } = mapResourceId(
        state,
        payload.resourceId,
      );

      if (workspaceIndex !== -1 && resourceIndex !== -1) {
        state.items[workspaceIndex].items[resourceIndex] = payload;
      }
    },

    setResourceItemName(state: State, payload: UpdateName): void {
      const { workspaceIndex, resourceIndex } = mapResourceId(
        state,
        payload.resourceId,
      );

      if (workspaceIndex !== -1 && resourceIndex !== -1) {
        state.items[workspaceIndex].items[resourceIndex].title = payload.name;
      }
    },
  },

  actions: {
    async fetchItems({ commit }: ActionContext<State, R>): Promise<void> {
      const items = await api.workspaces.index();
      commit('setItems', { items });
    },

    async saveItem(
      { commit, state }: ActionContext<State, R>,
      payload: StoreItem,
    ): Promise<void> {
      const items = await api.workspaces.store({
        title: payload.title,
        order: String(state.items.length + 1),
        wsType: payload.wsType,
        wsKind: payload.wsKind,
      });

      commit('setItems', { items });
    },

    async updateItem(
      { commit }: ActionContext<State, R>,
      payload: UpdateItem,
    ): Promise<void> {
      const items = await api.workspaces.update(payload);
      commit('setItems', { items });
    },

    async deleteItem(
      { commit }: ActionContext<State, R>,
      payload: DeleteItem,
    ): Promise<void> {
      const items = await api.workspaces.del(payload);
      commit('setItems', { items });
    },

    async updateResourceItem(
      { commit, state }: ActionContext<State, R>,
      payload: UpdateResource,
    ): Promise<void> {
      const { workspace, resource } = mapResourceId(state, payload.resourceId);

      const settings = {
        ...(resource?.settings || {}),
        ...(payload?.settings || {}),
      };

      if (payload?.settings) {
        commit('updateResource', { ...resource, settings });
      }

      const items = await api.workspaces.updateResource({
        id: payload.id || workspace?.id,
        ...payload,
        order: payload.order,
        settings,
      });

      commit('setItems', { items });
    },

    async renameResourceItem(
      { commit }: ActionContext<State, R>,
      payload: UpdateName,
    ): Promise<void> {
      await api.queries.updateName(payload);
      commit('setResourceItemName', payload);
    },

    async duplicateResourceItem(
      { commit, state }: ActionContext<State, R>,
      payload: { id?: string; resourceId: string; source: boolean },
    ): Promise<void> {
      const { workspace } = mapResourceId(state, payload.resourceId);
      const newWorkspace = state.items.find((item) => item.id === payload.id);
      const order = (newWorkspace || workspace).items.length + 1;

      const query = await api.queries.show(payload.resourceId);
      const newTitle = `${query.name} (${i18n.global.t('common.copy')})`;
      const newQuery = await api.queries.store({
        ...query,
        name: newTitle,
      });

      const items = await api.workspaces.storeResource({
        title: newTitle,
        id: payload.id || workspace.id,
        resourceId: newQuery.id,
        order: String(order),
        resourceKind: RESOURCE_KINDS.QUERY,
      });

      commit('setItems', { items });
    },

    async deleteResourceItem(
      { commit, state }: ActionContext<State, R>,
      payload: DeleteResource,
    ): Promise<void> {
      const { workspace } = mapResourceId(state, payload.resourceId);

      const items = await api.workspaces.deleteResource({
        id: workspace?.id,
        resourceId: payload.resourceId,
      });

      commit('setItems', { items });
    },

    async saveSearchQuery(
      { commit, state }: ActionContext<State, R>,
      payload: StoreQuery & StoreResource,
    ): Promise<void> {
      const workspace = state.items.find((item) => item.id === payload.id);
      const order = (workspace?.items.length || 0) + 1;

      const query = await api.queries.store(payload);
      const items = await api.workspaces.storeResource({
        order: String(order),
        resourceId: query.id,
        resourceKind: RESOURCE_KINDS.QUERY,
        ...payload,
      });

      commit('setItems', { items });
    },

    async updateSearchQuery(
      _: ActionContext<State, R>,
      payload: StoreQuery,
    ): Promise<void> {
      await api.queries.update(payload);
    },
  },

  getters: {
    getItems: (state: State, getters: any, rootState: R): Workspace[] =>
      state.items.filter((item) => {
        switch (item.wsType) {
          case WORKSPACE_TYPES.SYSTEM:
            return rootState.auth.user?.features.useSystemWorkspace;
          case WORKSPACE_TYPES.SHARED:
            return rootState.auth.user?.features.useSharedWorkspaces;
          default:
            return true;
        }
      }),

    getItem:
      (state: State) =>
      (id: string): Workspace | undefined =>
        state.items.find((item) => item.id === id) || state.items[0],

    resources: (state: State): Resource[] =>
      state.items
        .filter(
          (item) =>
            item.wsKind === WORKSPACE_KINDS.COLUMN &&
            item.wsType !== WORKSPACE_TYPES.SYSTEM,
        )
        .map((item) => item.items)
        .flat(),

    calendarEnabled: (state: State): boolean =>
      state.items.some(
        (item) =>
          item.wsKind === WORKSPACE_KINDS.CALENDARS ||
          item.wsKind === WORKSPACE_KINDS.CALENDAR,
      ),

    newsEnabled: (state: State, getters: any, rootState: R): boolean =>
      rootState.sources.items.length > 0,
  },
};
