import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!

  return (_openBlock(), _createBlock(_component_Column, {
    resourceId: _ctx.resourceId,
    isLoading: _ctx.isLoading,
    title: _ctx.column.title,
    items: _ctx.column.items
  }, null, 8, ["resourceId", "isLoading", "title", "items"]))
}